import React, { useEffect, useState } from "react";
import { Icon } from "components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { setPodcasts } from "stores/podcastSlice";

export default function Search() {
  const dispatch = useDispatch();
  const podcasts = useSelector((state) => state.podcast?.podcasts);
  const [searchedData, setSearchedData] = useState(podcasts);
  const [query, setQuery] = useState("");

  const handleSearch = () => {
    const filteredPodcasts = searchedData.filter(
      (podcast) =>
        podcast.podcast_name.toLowerCase().includes(query.toLocaleLowerCase()) ||
        podcast.podcast_category.category_name.toLowerCase().includes(query.toLocaleLowerCase())
    );
    if (query.length > 0) {
      dispatch(setPodcasts(filteredPodcasts));
    } else {
      setSearchedData(podcasts);
      dispatch(setPodcasts(podcasts));
    }
  };

  useEffect(() => {
    if (query) {
      handleSearch();
    } else {
    }
  }, [query]);

  return (
    <div className="mx-auto mt-4 relative">
  <label
    htmlFor="search-input"
    className="text-black w-8 h-8 flex items-center justify-center absolute top-0 left-0"
  >
    <Icon size={20} name="topsearch" />
  </label>
  <input
    autoFocus={true}
    type="text"
    id="search-input"
    className="h-8 pl-8 outline-none text-black font-medium bg-white rounded-full text-sm placeholder-black/50 sm:w-40 md:w-48 lg:w-56 xl:w-64"
    // Adjusted width for smaller screens
    placeholder="Search podcasts..."
    value={query}
    onChange={(e) => setQuery(e.target.value)}
  />
</div>


  );
}
