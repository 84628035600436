"use client";
// imports
import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  // ModalTitle,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { setCurrent } from "stores/player";
import ApiService from "apis/apiService";

import { useParams } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

export default function PodcastDetails() {
  const podcasts = useSelector((state) => state.podcast?.podcasts);
  const params = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token);

  const { current } = useSelector((state) => state.player);
  const showdata = localStorage.getItem("userdata");
  const [usercheckpaid, setUserCheckpaid] = useState(false);

  const userplan = async () => {
    try {
    let response = await ApiService.get(`api/auth/user`, token);
      setUserCheckpaid(response.user.isPaid);

      if (response.user.isPaid === true && showdata !== null) {
        return;
      } else {
        window.location.href = "/demo";
      }
    } catch (error) {
      console.error("Error sending email:", error);
      if (error.response && error.response.status === 403) {
        window.location.href = "/demo";
      }
      // showToast("error", "Failed to send purchase confirmation email");
    }
  };
  console.log("showdata", showdata);

  useEffect(() => {
    userplan();
  }, []);
  // const getDetails = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL_PROD}api/update-file/details_links`
  //     );
  //     setAdData(response.data.link_details);

  //     setLoading(false);
  //   } catch (error) {
  //     console.log("[Networ Error]", error);
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   // getDetails();
  //   setShareOpen(true);
  //   onOpen();
  // }, []);

  
  const getPodcastNameById = (podcastId) => {
    const podcast = podcasts.find((c) => c?.podcast_id === podcastId);
    return podcast;
  };
  console.log("podcasts----->", podcasts);

  useEffect(() => {
    dispatch(setCurrent(current));
  }, [current]);
  return (
    <Container maxW={"7xl"} px={4} className="podcastDetails-page">
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 8, md: 12 }}
      >
        {/* Podcast Image */}


        {/* Podcast Details */}
        <Stack spacing={{ base: 6, md: 10 }}>

          {/* Podcast Text and Details */}
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={"column"}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.200", "gray.600")}
              />
            }
          >
            <VStack spacing={{ base: 4, sm: 6 }}>
            <Image
            rounded={"md"}
            alt={"product image"}
            src={
              getPodcastNameById(params?.id)?.podcast_image
                ? getPodcastNameById(params?.id)?.podcast_image
                : ""
            }
            fit={"cover"}
            align={"center"}
            w={"100%"}
            h={{ base: "100%", sm: "300px", lg: "400px" }}
          />
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "3xl", lg: "4xl" }}
            >
              {getPodcastNameById(params?.id)?.podcast_name}
            </Heading>
            <Badge variant="subtle" colorScheme="green">
              {getPodcastNameById(params?.id)?.podcast_category?.category_name}
            </Badge>
              <Text className="podcastDetails-sub-heading"
                color={useColorModeValue("gray.300", "gray.300")}
                fontSize={{ base: "md", md: "lg" }}
                fontWeight={"300"}
                textAlign={"justify"}
              >
                {getPodcastNameById(params?.id)?.podcast_details}
              </Text>

              <Text
                fontSize={{ base: "lg", md: "xl" }}
                dangerouslySetInnerHTML={{
                  __html: getPodcastNameById(params?.id)?.podcast_text,
                }}
              >
                {/* {getPodcastNameById(params?.id)?.podcast_text} */}
              </Text>
            </VStack>
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
}
