"use client";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  Avatar,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";

export default function UserProfile() {
  const user = useSelector((state) => state?.auth?.user);

  return (
    <Flex align={"center"} justify={"center"}>
  <Stack className="user-profile-page"
    spacing={4}
    w={"full"}
    maxW={"md"}
    bg={useColorModeValue("black", "black")}
    rounded={"xl"}
    boxShadow={"lg"}
    p={6}
    my={12}
  >
    <Heading lineHeight={1.1} fontSize={{ base: "xl", sm: "2xl" }}>
      User Profile
    </Heading>
    <FormControl id="userName">
      <Stack
        display={"flex"}
        alignItems={"center"}
        spacing={4}
        flexDirection={{ base: "column", sm: "row" }}
      >
        <Avatar
          borderWidth={3}
          borderColor={"green.400"}
          size={{ base: "xl", sm: "2xl" }}
          src={user?.photo ? user?.photo : "https://bit.ly/sage-adebayo"}
        />
        {/* Uncomment the following lines if you want to add a "Change Icon" button */}
        {/* <Button colorScheme="gray" size="sm" ml={{ base: 0, sm: 4 }}>
          Change Icon
        </Button> */}
      </Stack>
    </FormControl>
    <FormControl id="name" isRequired>
      <FormLabel>Name</FormLabel>
      <Input
        placeholder="Name"
        _placeholder={{ color: "gray.500" }}
        type="text"
        value={user?.name}
        disabled
      />
    </FormControl>
    <FormControl id="email" isRequired>
      <FormLabel>Email address</FormLabel>
      <Input
        placeholder="abc@abc.com"
        _placeholder={{ color: "gray.500" }}
        type="email"
        value={user?.email}
        disabled
      />
    </FormControl>
    {/* Uncomment the following lines if you want to include a password input field */}
    {/* <FormControl id="password" isRequired>
      <FormLabel>Password</FormLabel>
      <Input
        placeholder="password"
        _placeholder={{ color: "gray.500" }}
        type="password"
      />
    </FormControl> */}
    {/* Uncomment the following lines if you want to include Cancel and Submit buttons */}
    {/* <Stack spacing={4} direction={{ base: "column", sm: "row" }}>
      <Button
        bg={"black"}
        color={"white"}
        w={{ base: "full", sm: "auto" }}
        _hover={{
          bg: "gray.900",
        }}
      >
        Cancel
      </Button>
      <Button
        bg={"green.400"}
        color={"white"}
        w={{ base: "full", sm: "auto" }}
        _hover={{
          bg: "green.500",
        }}
      >
        Submit
      </Button>
    </Stack> */}
  </Stack>
    </Flex>

  );
}
