import { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import { useToast } from "components/toast/Toaster";
import { useNavigate } from "react-router";
import { Spinner } from "@chakra-ui/spinner";
import ApiService from "apis/apiService";
import axios from "axios";
import { BiLogoGoogle } from "react-icons/bi";
import { useGoogleLogin } from "@react-oauth/google";

export default function Register() {
  // hooks
  const { showToast } = useToast();
  const navigate = useNavigate();
  // states
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [gAuthUser, setGAuthUser] = useState(null);
  const [gAuthLoading, setGAuthLoading] = useState(false);
  // functions
  const handleRegisterUser = async (data) => {
    let payload = {};
    if (data) {
      payload = data;
    } else {
      if (!username) {
        showToast("error", "Please enter a username");
      } else if (!email) {
        showToast("error", "Please enter email");
      } else if (!password) {
        showToast("error", "Please enter a password");
      } else if (password.length < 8) {
        showToast("error", "Please enter a password of atleast 8 characters");
      } else {
        payload = {
          username: username,
          email: email,
          password: password,
          role: "user",
        };
      }
    }
    try {
      data ? setGAuthLoading(true) : setLoading(true);
      const response = await ApiService.post("api/auth/register", payload);
      if (response?.success) {
        showToast("success", response?.message);
        setEmail("");
        setUsername("");
        setPassword("");
        navigate("/login", { replace: true });
      } else {
        showToast("error", response?.message);
      }
      data ? setGAuthLoading(false) : setLoading(false);
    } catch (error) {
      console.log("[Networ Error]:: ", error?.message);
      showToast("error", error.message);
      data ? setGAuthLoading(false) : setLoading(false);
    }
  };
  // google authentication
  const google_login = useGoogleLogin({
    onSuccess: (codeResponse) => setGAuthUser(codeResponse),
    onError: (error) => console.log("Google Authentication Failed:", error),
  });
  console.log("gAuthUser", gAuthUser);
  useEffect(() => {
    if (gAuthUser) {
      setGAuthLoading(true);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${gAuthUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${gAuthUser.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("getProfileData---------", { getProfileData: res?.data });
          handleRegisterUser({
            username: res?.data?.given_name,
            email: res?.data?.email,
            password: res?.data?.id,
            role: "user",
          });
        })
        .catch((err) => console.log(err))
        .finally(() => setGAuthLoading(false));
    }
  }, [gAuthUser]);

  const handlregister = () => {
    navigate("/login", { replace: true });
  };
  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-white sign-text">
          Register Here
        </h4>
        <button
          onClick={() => google_login()}
          className="linear my-2 w-full rounded-xl bg-white py-[12px] text-base font-medium text-black transition duration-200 hover:bg-gray-300 hover:text-black active:bg-green-700"
        >
          {gAuthLoading ? (
            <Spinner size={"md"} />
          ) : (
            <span className="flex items-center justify-center">
              <BiLogoGoogle size={28} className="mr-4" /> Continue with Google
            </span>
          )}
        </button>
        <p className="mb-9 ml-1 text-base text-gray-300">
          Please create an account to continue
        </p>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Username*"
          placeholder="abc1234"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="abc@abc.com"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          disabled={loading}
          onClick={() => handleRegisterUser()}
          className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700"
        >
          {loading ? <Spinner size={"md"} /> : "Register"}
        </button>
        <div className="mt-4">
          <span
            className=" text-sm font-medium text-white"
            style={{ fontSize: "20px", marginLeft: "132px" }}
          >
            Already registered?
          </span>
          <button
            onClick={handlregister}
            className="linear mt-2 w-full rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
}
