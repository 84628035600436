import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter, AiFillYoutube } from "react-icons/ai";
import { BiLogoLinkedin, BiLogoPinterestAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import LOGO from "components/image/logos.jpg";

const Footer = () => {
  const iconsTab = [
    {
      icon: <FaFacebookF />,
      link: "https://www.facebook.com/PodcastGlance?mibextid=ZbWKwL",
    },
    {
      icon: <AiOutlineTwitter />,
      link: "https://x.com/PodcastGlance?t=5PtOgbQ5L4oiO1tQ12QiFw&s=09",
    },
    {
      icon: <AiFillYoutube />,
      link: "https://youtube.com/@PodcastGlance?feature=shared",
    },
    {
      icon: <BiLogoLinkedin />,
      link: "https://www.linkedin.com/company/podcastglance/",
    },
  ];

  return (
    <>
      <footer className="mb-4 mt-8">
        <div className="container">
          <div className="flex flex-col md:flex-row items-center md:items-start md:gap-[5rem] text-center md:text-left">
            {/* Company Details */}
            <div className="flex flex-col w-full md:w-auto md:p-0 py-4 gap-8">
              <div className="flex flex-col gap-4">
                <p className="text-lg font-bold">Company Details</p>
                {/* <a className="text-sm md:text-base" href="#/privacy">
                  Privacy policy
                </a> */}
                <Link to="/privacy">Privacy Policy</Link>
                <Link to="/contact-us">Contact us</Link>
                {/* <p className="text-sm md:text-base">Contact us</p> */}
              </div>
              <div className="flex gap-8 mt-4 md:mt-0 justify-center md:justify-end footer-social-icon">
                {iconsTab.map((item, index) => (
                  <a
                    href={item?.link}
                    key={index}
                    className="text-2xl bg-[#ffffff] p-2 rounded-full hover:bg-[#48BB78] hover:text-white"
                    style={{ transition: "all 0.3s" }}
                  >
                    <div className="text-black hover:text-white">{item?.icon}</div>
                  </a>
                ))}
              </div>
            </div>

            {/* Placeholder for Future Content */}
            <div className="flex flex-col gap-8 relative"></div>

            {/* Placeholder for Future Content */}
            <div className="flex flex-col gap-8 relative"></div>
          </div>
        </div>

        {/* Copyright Information */}
        <div className="text-center my-4">
          <p className="text-xs md:text-sm font-medium text-[#646464]">
            All rights reserved by PodcastGlance © {new Date().getFullYear()}
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
