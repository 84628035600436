import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Icon } from "components/Icons";
import { useAudio } from "react-use";
import MyRange from "components/MyRange";
import { useSelector } from "react-redux";
import { setControls } from "stores/player";
import { useDispatch } from "react-redux";
import { useSpeechSynthesis } from "react-speech-kit";
import { useToast } from "components/toast/Toaster";
import ApiService from "apis/apiService";
import { setLikedSlice } from "stores/likedSlice";
import {
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  PinterestShareButton,
  PinterestIcon,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  InstagramShareButton,
  LinkedinIcon,
  InstagramIcon,
} from "react-share";
import { setPodcasts } from "stores/podcastSlice";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RiFileCopyLine } from "react-icons/ri";
import { FaInstagram, FaInstagramSquare } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactH5AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { InstagramEmbed } from "react-social-media-embed";
import { setCurrent } from "../../stores/player";

export default function Player({ playVoiceModal }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [titleProduct, settitleProduct] = useState("");
  const { showToast } = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const podcasts = useSelector((state) => state.podcast?.podcasts);
  const [audioSrc, setAudioSrc] = useState("");
  const audioPlayerRef = useRef(null);

  const { current } = useSelector((state) => state.player);

  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state?.auth?.token);
  const likedPodcasts = useSelector(
    (state) => state?.likedPodcasts?.likedPodcasts
  );
  const isCurrentInLikedPodcasts = likedPodcasts.filter(
    (item) => item?.podcast_id === current?.podcast_id
  );
  console.log("current", current)
  const getAllPodcasts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL_PROD}api/podcasts/get-all-podcasts`
      );
      if (response?.data?.success) {
        dispatch(setPodcasts(response?.data?.data));
      } else {
        showToast("error", response?.message);
      }
      setLoading(false);
    } catch (error) {
      console.log("[Networ Error]", error);
      showToast("error", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setCurrent(current));
  }, [current]);
  
  const handlePlay = () => {
    dispatch(setControls(current));
  };

  useEffect(() => {
    getAllPodcasts();
  }, []);
  const preferredAccents = ["en-US", "en-GB"];
  dispatch(setCurrent(current));

  const [time, setTime] = useState("00:00");
  const [maxTime, setMaxTime] = useState(1);
  const [value, setValue] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [likedLoading, setLikedLoading] = useState(false);
  const { speak, cancel, speaking, voices } = useSpeechSynthesis();
  const [shareOpen, setShareOpen] = useState(false);
  const [isPlayings, setIsPlayings] = useState(false);

  const handleLikedPodcasts = async () => {
    setLikedLoading(true);
    if (user) {
      const userId = user?.id;
      const podcast_id = current.podcast_id;
      let response = await ApiService.post(
        `api/podcasts/like/${userId}/${podcast_id}`,
        {},
        token
      );
      if (response?.success) {
        showToast("success", response?.message);
      } else {
        handleDislikePodcast();
        // showToast("error", response?.message);
      }
      getAllLikedPodcasts();
      setLikedLoading(false);
    } else {
      setLikedLoading(false);
      showToast("error", "Please login first!");
    }
  };
  const getAllLikedPodcasts = async () => {
    let userId = user?.id;
    try {
      let response = await ApiService.get(
        `api/podcasts/liked/${userId}`,
        token
      );
      if (response?.success) {
        if (response?.data?.length > 0) {
          dispatch(setLikedSlice(response?.data));
        }
      }
    } catch (error) {
      console.log("Network error:", error?.message);
    }
  };
  const handleDislikePodcast = async () => {
    setLikedLoading(true);
    if (user) {
      const userId = user?.id;
      const podcast_id = current?.podcast_id;
      let response = await ApiService.put(
        `api/podcasts/dislike/${userId}/${podcast_id}`,
        {},
        token
      );
      if (response?.success) {
        showToast("success", response?.message);
        getAllLikedPodcasts();
      } else {
        showToast("error", response?.message);
      }
      getAllLikedPodcasts();
      setLikedLoading(false);
    } else {
      setLikedLoading(false);
      showToast("error", "Please login first!");
    }
  };

  const [currentTime, setCurrentTime] = useState(0);

  const handleShare = () => {
    dispatch(setCurrent(current));
    setShareUrl(
      `https://podcastglance.com/#/podcastDetails/${current?.podcast_id}`
    );
    settitleProduct(`${current?.podcast_name}`);
    setShareOpen(true);
    onOpen();
  };

  const handleInstagramShare = () => {
    const message = `${shareUrl}`;
    navigator.clipboard
      .writeText(message)
      .then(() => {
        toast.success("URL copied to clipboard:", message);
        window.location.href = "instagram://";
        setTimeout(() => {
          window.location.href = "https://www.instagram.com/";
        }, 2000);
      })
      .catch((error) => {
        console.error("Failed to copy message to clipboard:", error);
        toast.error("Failed to copy message to clipboard. Please try again.");
      });
  };
  const handleCopyToClipboard = () => {
    // dispatch(setCurrent(current));
    dispatch(setCurrent(current));
    navigator?.clipboard?.writeText(shareUrl);
    toast.success("URL copied to clipboard");
    setTimeout(() => {
      toast.dismiss();
    }, 2000);
  };

  const handlePlayPause = () => {
    setIsPlayings(!isPlayings);
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };
  const audioRef = useRef(null);

  useEffect(() => {
    if (!audioRef.current) return;

    if (!playVoiceModal) {
      audioRef.current.audio.current.pause();
    } else {
      audioRef.current.audio.current.play();
    }
  }, [playVoiceModal]);

  const handleTimeUpdate = (e) => {
    setCurrentTime(e.target.currentTime);
  };

  return (
    <div className="flex justify-center items-center px-4">
      <div className="min-w-[11.25rem] w-[30%]ß">
        {current ? (
          <div className="flex items-center">
            <div className="flex items-center justify-between">
              <div className="w-12 h-12 mr-3 rounded-md  hidden sm:block">
                <img
                  src={
                    current?.podcast_image ??
                    "https://t.scdn.co/images/0a74d96e091a495bb09c0d83210910c3"
                  }
                  className="rounded-md w-24"
                />
              </div>
              <div className="hidden sm:block">
                <h4 className="text-sm text-white">{current?.podcast_name}</h4>
                <h6 className="text-[0.9rem] text-tartist">
                  {current?.podcast_category?.category_name}
                </h6>
              </div>
            </div>
            <div className="flex items-center justify-around ml-2 md:ml-0">
              <button
                onClick={() => handleLikedPodcasts()}
                className={`w-8 h-8 flex items-center justify-center text-opacity-70 hover:text-opacity-100 ml-4 ${
                  isCurrentInLikedPodcasts?.length > 0
                    ? "text-green-500"
                    : "text-gray-300"
                }`}
              >
                {likedLoading ? (
                  <Spinner size={"sm"} />
                ) : (
                  <Icon size={16} name="wishlist" className="text-green-600" />
                )}
              </button>
              {/* {podcasts && podcasts?.length > 0 && podcasts?.map((podcast) => ( */}
              <button
                // key={podcast.id}
                className="w-8 h-8 flex items-center justify-center text-white text-opacity-70 hover:text-opacity-100 "
                onClick={() => handleShare()}
              >
                <Icon size={16} name="share" />{" "}
              </button>
              {/* ))} */}
            </div>
          </div>
        ) : (
          "not found"
        )}
      </div>
      <div className="flex flex-col items-center max-w-[45.125rem] sm:w-[40%]">
        <div className="flex gap-x-3"></div>
        <div className="w-full flex items-center gap-x-2">
          {current && current.podcast_audio && (
            <ReactH5AudioPlayer
              ref={audioRef}
              src={current.podcast_audio}
              autoPlay={true}
              onEnded={handleAudioEnded}
              onPlay={handlePlay}
            />
          )}
        </div>
      </div>
      <div className="min-w-[11.25rem] flex items-center justify-end"></div>
      {shareOpen && (
        <>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay>
              <ModalContent>
                <ModalHeader>Share</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <>
                    <FacebookShareButton
                      url={shareUrl}
                      quote={titleProduct}
                      hashtag={"#portfolio..."}
                    >
                      <FacebookIcon size={40} round={true} />
                    </FacebookShareButton>{" "}
                    {""}
                    <WhatsappShareButton
                      url={shareUrl}
                      quote={titleProduct}
                      hashtag={"#portfolio..."}
                    >
                      <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>{" "}
                    {""}
                    <TwitterShareButton url={shareUrl} title={titleProduct}>
                      <TwitterIcon size={40} round={true} />
                    </TwitterShareButton>{" "}
                    {""}
                    <TelegramShareButton url={shareUrl} title={titleProduct}>
                      <TelegramIcon size={40} round={true} />
                    </TelegramShareButton>
                    <LinkedinShareButton url={shareUrl} title={titleProduct}>
                      <LinkedinIcon size={40} round={true} />
                    </LinkedinShareButton>
                    <div className="copyshare-link">
                      <CopyToClipboard text={shareUrl}>
                        <button>
                          <RiFileCopyLine
                            onClick={handleCopyToClipboard}
                            size={37}
                            url={shareUrl}
                            title={titleProduct}
                          />
                        </button>
                      </CopyToClipboard>{" "}
                      <button
                        className="instagram-button"
                        onClick={handleInstagramShare}
                      >
                        <FaInstagram size={38} />
                      </button>
                    </div>
                  </>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </ModalOverlay>
          </Modal>
          <ToastContainer />
        </>
      )}
    </div>
  );
}
