import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import { ChakraProvider } from "@chakra-ui/react";
// stores
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./stores";
import PrivacyPolicy from "./views/PrivacyPolicyPage.js";
// styles
import "style.css";
import "./index.css";
import { HashRouter as Router, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GDC_CLIENT_ID}>
            <Router>
              <App />
              <ToastContainer />
            </Router>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
