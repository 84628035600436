import React, { useEffect, useRef, useState } from "react";
import { Icon } from "components/Icons";
import { useDispatch } from "react-redux";
import { setCurrent } from "stores/player";
import { useSelector } from "react-redux";
import {
    Box,
    Container,
    Stack,
    Text,
    Image,
    Flex,
    VStack,
    Heading,
    SimpleGrid,
    StackDivider,
    useColorModeValue,
    Badge,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    // ModalTitle,
    ModalCloseButton,
    Button,
} from "@chakra-ui/react";

import { useSpeechSynthesis } from "react-speech-kit";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "apis/apiService";

export default function Song({ item, index, AdsOpenModal, key }) {
    console.log("item", item)
    const dispatch = useDispatch();
    const { current } = useSelector((state) => state.player);
    const { speak, cancel, speaking, voices } = useSpeechSynthesis();
    const [shareOpen, setShareOpen] = useState(false);
    const [AdData, setAdData] = useState({});
    const [loading, setLoading] = useState(false);
    const videoRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure({
        closeOnOverlayClick: false,
    });
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(true);
    const [closeButtonDisablednon, setCloseButtonDisablednon] = useState(true);
    const [usercheckpaid, setUserCheckpaid] = useState(false);

    const showdata = localStorage.getItem("userdata");
    const location = useLocation();
    const navigate = useNavigate();
    const token = useSelector((state) => state?.auth?.token);
    const [showMore, setShowMore] = useState(false);
    const [hasDispatchedItem, setHasDispatchedItem] = useState(false);

    const updateCurrent = async () => {
        // AdsOpenModal(false)

        // if (isOpen) {
        // console.log("Modal is open. Not dispatching setCurrent.");
        // console.log("Stopping podcast playback...");
        // dispatch(setCurrent(null));
        // return;
        // }
        if (showdata === null) {
            setShareOpen(true);
            onOpen();
            setTimeout(() => {
                setCloseButtonDisablednon(false);
            }, 5000);
            return;
        }
        try {
            let response = await ApiService.get(`api/auth/user`, token);
            setUserCheckpaid(response.user.isPaid);
            if (response.user.isPaid === true) {
                setShareOpen(false);
                AdsOpenModal(false);
                window.location.href = `/#/podcastDetails/${item?.podcast_id}`;
                // window.location.href = `/demo/#/podcastDetails/${item?.podcast_id}`;
                dispatch(setCurrent(item));
            } else {
                setShareOpen(true);
                onOpen();
                AdsOpenModal(false);
            }
        } catch (error) {
            console.error("Error sending email:", error);
            // showToast("error", "Failed to send purchase confirmation email");
        }
        // onOpen();
        // dispatch(setCurrent(item));
        // if (usercheckpaid === true) {
        //   dispatch(setCurrent(item));
        // }
        if (usercheckpaid === false) {
            setTimeout(() => {
                setCloseButtonDisablednon(false);
            }, 5000);
        }
        setTimeout(() => {
            setCloseButtonDisabled(false);
        }, 5000);
        dispatch(setCurrent(item));
    };
    const getDetails = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL_PROD}api/update-file/details_links`
            );
            setAdData(response.data.link_details);
            setLoading(false);
        } catch (error) {
            console.log("[Networ Error]", error);
            setLoading(false);
        }
    };
    // const sendPurchaseConfirmationEmail = async () => {
    //   try {
    //     // const showdata = localStorage.getItem("token");
    //     let response = await ApiService.get(`api/auth/user`, token);

    //     console.log("responseqdsdsf", response);
    //   } catch (error) {
    //     console.error("Error sending email:", error);
    //     // showToast("error", "Failed to send purchase confirmation email");
    //   }
    // };
    const handlAdd = async () => {
        // setShareOpen(true);

        // cancel();
        // dispatch(setCurrent(item));
        // setShareOpen(true);
        // dispatch(setCurrent(null));

        if (showdata === null) {
            setShareOpen(true);

            onOpen();
            setTimeout(() => {
                setCloseButtonDisablednon(false);
            }, 5000);
            return;
        }
        try {
            let response = await ApiService.get(`api/auth/user`, token);
            setUserCheckpaid(response.user.isPaid);
            if (response.user.isPaid === true) {
                setShareOpen(false);
                AdsOpenModal(false);

                window.location.href = `/#/podcastDetails/${item?.podcast_id}`;
                dispatch(setCurrent(item));
            } else {
                setShareOpen(true);
                onOpen();
                AdsOpenModal(false);
            }
            // dispatch(setCurrent(item));
        } catch (error) {
            console.error("Error sending email:", error);
            // showToast("error", "Failed to send purchase confirmation email");
        }
        // onOpen();
        if (usercheckpaid === false) {
            setTimeout(() => {
                setCloseButtonDisablednon(false);
            }, 5000);
        }
        setTimeout(() => {
            setCloseButtonDisabled(false);
        }, 5000);
    };
    const vidRef = useRef();

    useEffect(() => {
        vidRef.current?.play();
    }, [isOpen]);

    const handleClose = () => {
        cancel();
        dispatch(setCurrent(item));
        window.location.href = `/#/podcastDetails/${item?.podcast_id}`;
    };
    const handledisblehome = () => {
        onClose();
        AdsOpenModal(true);
        cancel();
        dispatch(setCurrent(item));
        if (!window.location.pathname.includes("/#/")) {
            navigate("");
        }
    };
    useEffect(() => {
        if (!isOpen && hasDispatchedItem) {
            setHasDispatchedItem(false);
        }
    }, [isOpen]);
    const onplanpurchase = () => {
        onClose();
        window.location.href = "/#/subscription";
    };
    useEffect(() => {
        // cancel();
        getDetails();
    }, [item]);

    const WithOutLogin = () => {
        setCloseButtonDisablednon(true);
        navigate("/#");
    }
    useEffect(() => {
        dispatch(setCurrent(item));
    }, [item]);
    return (
        <>
            <div
                key={item?.podcast_id}
                className={"bg-footer rounded p-4 transition-all hover:bg-menubg group"}
            >
                <div className="relative ">
                    <img
                        className="w-auto h-auto inset-0 object-cover mb-4"
                        src={
                            item?.podcast_image
                                ? item?.podcast_image
                                : "https://t.scdn.co/images/0a74d96e091a495bb09c0d83210910c3"
                        }
                    />
                    <button
                        onClick={updateCurrent}
                        className="w-12 h-12 bg-primary absolute right-2 bottom-2 transition-opacity rounded-full flex items-center  justify-center opacity-0 group-hover:opacity-100 group-active:opacity-100"
                    >
                        <Icon name={!current?.id == item?.id ? "pause" : "play"} />
                    </button>
                </div>
                {/* <a href={`/demo/#/podcastDetails/${item?.podcast_id}`} onClick={handlAdd}> */}
                <div
                    className="font-semibold text-white text-[16px] whitespace-nowrap truncate"
                    style={{ cursor: "pointer" }}
                    onClick={handlAdd}
                >
                    {item?.podcast_name}
                </div>
                <div className="mt-1 text-[14px] text-link flex items-center gap-2">
                    <Badge colorScheme="green">{item?.added_by}</Badge>
                    <Badge colorScheme="purple">
                        {item?.podcast_category?.category_name}
                    </Badge>
                </div>
                {/* </a> */}
                {shareOpen && AdData && AdData?.link && (
                    <Modal
                        isOpen={isOpen}
                        onClose={handledisblehome}
                        closeOnOverlayClick={false}
                    >
                        <ModalOverlay>
                            <ModalContent maxH="500px" maxW="600px">
                                <ModalHeader>Ads</ModalHeader>
                                {showdata === null ? (
                                    <ModalCloseButton
                                        disabled={closeButtonDisablednon} onClick={WithOutLogin} />
                                ) : usercheckpaid === false ? (
                                    <ModalCloseButton
                                        disabled={closeButtonDisablednon}
                                    // onClose={handledisblehome}
                                    />
                                ) : (
                                    <ModalCloseButton disabled={closeButtonDisabled} />
                                )}

                                <ModalBody>
                                    {AdData && AdData.link ? (
                                        AdData.link.endsWith(".mp4") ? (
                                            <video
                                                // src={AdData.link}
                                                // ref={vidRef}
                                                muted
                                                autoPlay
                                                loop
                                                style={{ maxWidth: "100%" }}
                                                onError={() => console.error("Failed to load video.")}
                                            >
                                                <source src={AdData.link} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) : AdData.link.includes("youtube.com") ||
                                            AdData.link.includes("youtu.be") ? (
                                            <iframe
                                                width="100%"
                                                height="315"
                                                src={`${AdData.link}?autoplay=1&start=0`}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            ></iframe>
                                        ) : (
                                            <img
                                                src={AdData.link}
                                                alt="Advertisement"
                                                style={{ width: "100%", height: "auto" }}
                                            />
                                        )
                                    ) : (
                                        <p>No video or image available.</p>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    {usercheckpaid === false ? (
                                        <Button colorScheme="blue" mr={3} onClick={onplanpurchase}>
                                            Purchase Plan
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </ModalFooter>
                            </ModalContent>
                        </ModalOverlay>
                    </Modal>
                )}
            </div>
        </>
    );
}
