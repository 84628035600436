import { Routes, Route } from "react-router-dom";
import Navbar from "components/Navbar";
import Home from "views/Home";
import Search from "views/Search";
import Footer from "./Footer";
import LikedPodcast from "views/LikedPodcast";
import PodcastDetails from "views/PodcastDetails";
import CategoryDetails from "views/CategoryDetails";
import Login from "views/auth/Login";
import Register from "views/auth/Register";
import { useSelector } from "react-redux";
import Subscription from "views/Subscription";
import UserProfile from "views/Profile";
import ForgetPassword from "views/auth/ForgetPassword";
import PrivacyPolicyPage from "views/PrivacyPolicyPage";
import ContactUsPage from "views/ContactUs";

export default function Content({userClick, AdsmodalOpen}) {
  const user = useSelector((state) => state?.auth?.user);
  

  return (
    <div className={`flex-auto overflow-auto podcast-right-side ${userClick ? 'podcast-right-side-new' : ''}`}>
      
      <Navbar />
      <div className="px-8 pt-5 main-content">
        <Routes>
          <Route exact path="/" element={<Home modalAdsOpen={AdsmodalOpen}/>} />
          <Route exact path="/login" element={user ? <Home modalAdsOpen={AdsmodalOpen} /> : <Login />} />
          <Route exact path="/forgot-password" element={<ForgetPassword />} />
          <Route
            exact
            path="/register"
            element={user ? <Home modalAdsOpen={AdsmodalOpen} /> : <Register />}
          />
          <Route
            exact
            path="/subscription"
            element={user ? <Subscription /> : <Login />}
          />
          <Route exact path="/search" element={<Search />} />
          <Route exact path="/liked" element={<LikedPodcast />} />
          <Route exact path="/profile" element={<UserProfile />} />

          <Route
            exact
            path="/podcastDetails/:id"
            element={<PodcastDetails />}
          />
          <Route
            exact
            path="/categoryDetails/:id"
            element={<CategoryDetails />}
          />
          <Route exact path="/privacy" element={<PrivacyPolicyPage />} />
          <Route exact path="/contact-us" element={<ContactUsPage />} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}
