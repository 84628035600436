import React from "react";
import { Menu } from "@headlessui/react";
import { Icon } from "components/Icons";
import logo from "../image/categories.jpg";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FaIdCard, FaPowerOff, FaUserCircle } from "react-icons/fa";
import { removeUser } from "stores/authSlice";
import { setLikedSlice } from "stores/likedSlice";

export default function User() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  console.log("user", user);
  const handleLogout = () => {
    dispatch(removeUser());
    dispatch(setLikedSlice([]));
    localStorage.removeItem("userdata");
    localStorage.removeItem("userid");
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <div>
        {!user ? (
          <button
            onClick={() => navigate("/login")}
            className={
              "flex items-center justify-center px-4 py-2 w-[100px] rounded p-1 bg-white"
            }
          >
            <p className="text-md text-bold text-black font-semibold">
              {"Login"}
            </p>
          </button>
        ) : (
          <Menu as="nav" className={"relative"}>
            {({ open: menuOpen }) => (
              <>
                <Menu.Button
                  className={`flex items-center gap-x-[6px] rounded-[23px] p-1 ${
                    menuOpen ? "bg-active" : "bg-brenk"
                  }`}
                >
                  <img
                    className="w-[28px] h-[28px] rounded-full"
                    src={user?.photo ? user?.photo : logo}
                  />
                  <span className="text-sm font-semibold">
                    {user?.username}
                  </span>
                  <span className={menuOpen === true && "rotate-180"}>
                    <Icon name="dir" />
                  </span>
                </Menu.Button>
                <Menu.Items className="absolute p-1 top-full right-0 w-48 bg-active rounded translate-y-2 z-10">
                  {/* <Menu.Item>
                    {({ active }) => (
                      <a
                        className={`h-10 flex px-2 text-sm font-semibold justify-between text-white opacity-80 items-center ${
                          active && "bg-menubg"
                        }`}
                        href="/#/subscription"
                      >
                        Subscription
                        <FaIdCard color="#21C55D" />
                      </a>
                    )}
                  </Menu.Item> */}
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        className={`h-10 flex px-2 text-sm font-semibold justify-between text-white opacity-80 items-center ${
                          active && "bg-menubg"
                        }`}
                        href="/#/profile"
                      >
                        Profile
                        <FaUserCircle />
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={`h-10 flex px-2 text-sm font-semibold justify-between text-white opacity-80 items-center ${
                          active && "bg-menubg"
                        }`}
                        onClick={() => handleLogout()}
                      >
                        Log out
                        <FaPowerOff color="red" name="external" />
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </>
            )}
          </Menu>
        )}
      </div>
    </>
  );
}
