import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  likedPodcasts: [],
};

export const likedSlice = createSlice({
  name: "likedPodcasts",
  initialState,
  reducers: {
    setLikedSlice: (state, action) => {
      // const { podcast_id } = action.payload;
      // const existingIndex = state.likedPodcasts.findIndex(
      //   (item) => item.podcast_id === podcast_id
      // );

      // if (existingIndex !== -1) {
      //   // If an item with the same podcast_id exists, remove it
      //   state.likedPodcasts.splice(existingIndex, 1);
      // } else {
      //   // If it doesn't exist, add it
      //   state.likedPodcasts.push(action.payload);
      // }
      state.likedPodcasts = action.payload;
    },
  },
});

export const { setLikedSlice } = likedSlice.actions;
export default likedSlice.reducer;
