import React, { useEffect, useState } from "react";
import PlayBox from "components/PlayBox";
import Song from "components/Song";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "components/toast/Toaster";
import { setPodcasts } from "stores/podcastSlice";
import axios from "axios";
import { Audio } from "react-loader-spinner";
import { setCategories } from "stores/categoriesSlice";
import { Badge, Stat, StatLabel, StatNumber } from "@chakra-ui/react";

const bgColors = [
  "purple.400",
  "blue.400",
  "green.400",
  "red.400",
  "yellow.400",
];

export default function Home({ modalAdsOpen }) {
  const podcasts = useSelector((state) => state.podcast?.podcasts);
  console.log("podcasts-------", podcasts)
  const categories = useSelector((state) => state.category?.categories);
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(3);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    getAllPodcasts();
  }, []);

  useEffect(() => {
    getAllCategories();
  }, [pageNumber]);

  const handlePageNumber = (value) => {
    if (value === "less") {
      setPageNumber(3);
    } else {
      setPageNumber(pageNumber + 7);
    }
  };
  const getAllPodcasts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL_PROD}api/podcasts/get-all-podcasts`
      );
      if (response?.data?.success) {
        dispatch(setPodcasts(response?.data?.data));
      } else {
        showToast("error", response?.message);
      }
      setLoading(false);
    } catch (error) {
      console.log("[Networ Error]", error);
      showToast("error", error.message);
      setLoading(false);
    }
  };
  const getAllCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL_PROD}api/category/get-all-categories?page=1&limit=${pageNumber}&sortBy=category_name`
      );
      if (response?.data?.success) {
        dispatch(setCategories(response?.data?.data));
        showToast("error", response?.message);
      } else {
        showToast("error", response?.message);
      }
      setLoading(false);
    } catch (error) {
      console.log("[Networ Error]", error);
      showToast("error", error.message);
      setLoading(false);
    }
  };
  const handleSeeMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="p-4 p-sm-0">
      {loading ? (
        <div className="flex w-full h-[50vh] items-center justify-center">
          <Audio
            height="30"
            width="30"
            color="#1BD760"
            ariaLabel="audio-loading"
            wrapperStyle={{}}
            wrapperClass="wrapper-class"
            visible={true}
          />
        </div>
      ) : (
        <div>
          <div className="mb-4">
            <h3 className="text-2xl text-white font-bold tracking-tight">
              Podcast Hosts
            </h3>
            <p
              className="text-gray-300 cursor-pointer text-sm"
              onClick={() => handlePageNumber(pageNumber > 3 ? "less" : "more")}
            >
              {pageNumber > 3 ? "See less" : "See all"}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-4 mb-6 home-playlist">
            {categories?.length > 0 ? (
              categories?.map((item, index) => (
                <a
                  href={`#/categoryDetails/${item?.category_id}`}
                  key={item?.category_id}
                >
                  <Stat
                    px={4}
                    py={5}
                    shadow="xl"
                    border="1px solid"
                    rounded="lg"
                    backgroundColor={bgColors[index % bgColors.length]}
                    borderColor={bgColors[index % bgColors.length]}
                  >
                    <StatLabel fontWeight="bold" fontSize="sm" isTruncated>
                      {item?.category_name}
                    </StatLabel>
                    <StatNumber fontSize="sm" fontWeight="medium">
                      <Badge colorScheme="gray">{item?.added_by}</Badge>
                    </StatNumber>
                  </Stat>
                </a>
              ))
            ) : (
              <div className="w-full">
                <p className="text-bold text-sm">Artists not found</p>
              </div>
            )}
          </div>
          <div className="mb-4">
            <PlayBox title="Podcast Playlists" />
            <p
              className="text-gray-300 cursor-pointer text-sm"
              onClick={handleSeeMore}
            >
              {showMore ? "See less" : "See all"}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-4">
            {podcasts && podcasts.length > 0 ? (
              <>
                {showMore
                  ? podcasts.map((item) => <Song item={item} key={item.id} AdsOpenModal={modalAdsOpen} />)
                  : podcasts
                      .slice(0, 10)
                      .map((item) => <Song item={item} key={item.id} AdsOpenModal={modalAdsOpen}/>)}
              </>
            ) : (
              <div className="w-full">
                <p className="text-bold text-sm">Podcasts not found</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
