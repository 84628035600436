"use client";

import { useState } from "react";
import { Box, Stack, Heading, VStack } from "@chakra-ui/react";
import {
  useStripe,
  useElements,
  CardElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
// components
import InputField from "components/fields/InputField";
// styles
import "./styles.css";
import Pricing from "components/Pricing";
import { useToast } from "components/toast/Toaster";
import axios from "axios";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "red",
      color: "#f7f7f7",
      fontSize: "16px",
      borderRadius: "20px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#f7f7f7",
      },
      "::placeholder": {
        color: "#707070",
      },
    },
    invalid: {
      iconColor: "#fc1000",
      color: "#fc1000",
    },
  },
};

export default function Subscription() {
  const { showToast } = useToast();
  const [plan, setPlan] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [errorss, setErrors] = useState(null);
  // const [metadata, setMetadata] = useState(null);
  const [formData, setFormData] = useState({
    fullname: "",
    cardNumber: "",
    email: localStorage.getItem("email"),
    username: localStorage.getItem("username"),
    id: localStorage.getItem("userid"),
  });
  // const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();

  const elements = useElements();
  // const cardNumber = elements.getElement(cardNumber);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errorss,
      [name]: null,
    });
  };
  const handleCardElementChange = (e) => {
    setFormData({
      ...formData,
      cardNumber: e.error ? "" : "valid",
    });
    setErrors({
      ...errorss,
      cardNumber: e.error ? "Invalid card number" : null,
    });
  };
  const validateFormStep = (data) => {
    let errors = {};

    if (!data?.fullname) {
      errors.fullname = "Name is required";
    }
    const cardNumber = elements.getElement(CardElement);

    if (cardNumber === "") {
      setErrors({ cardNumber: "Card number is required" });
      return;
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFormStep(formData);
    if (Object.keys(errors).length > 0) {
      console.log("errors", errors);
      setErrors(errors);
      return;
    }
    setProcessing(true);
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: formData.fullname,
          email: formData.email,
        },
      });
      if (error) {
        if (
          error.code === "card_declined" &&
          error.decline_code === "card_not_supported"
        ) {
          showToast("error", "Your card is not supported.");
        } else {
          showToast("error", error.message);
        }
      }
      console.log("paymentmethod", paymentMethod);
      const customer = await createStripeCustomer(paymentMethod);
      if (!customer) {
        showToast("Failed to create customer");
        setProcessing(false);
        return;
      }
      if (error) {
        showToast("Failed to create customer");
        setProcessing(false);
        return;
      }
      const subscription = await subscribeCustomerToPlan(customer.id, plan);
      console.log("Subscription created:", subscription);
      setError(null);
      setProcessing(false);
      setError(null);
      setPlan("");
      showToast("success", "You have successfully purchased the subscription");
    } catch (error) {
      console.error("Error creating subscription:", error);
      showToast("error", "Failed to create subscription");
      setProcessing(false);
    }
  };
  const createStripeCustomer = async (paymentMethod) => {
    try {
      const response = await axios("https://api.stripe.com/v1/customers", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        },
        data: {
          name: formData.fullname,
          payment_method: paymentMethod.id,
          email: formData.email,
          invoice_settings: {
            default_payment_method: paymentMethod.id,
          },
        },
      });
      if (error) {
        const errorMessage = response.error.message;
        console.log("errorMessage", errorMessage);
        showToast("error", `Failed to create customer: ${errorMessage}`);
      }
      if (response.status !== 200) {
        const errorMessage = response.error.message;
        console.log("errorMessage", errorMessage);
        showToast("error", `Failed to create customer: ${errorMessage}`);
      }
      return response.data;
    } catch (error) {
      console.log("Error creating customer:", error);
      showToast("error", "Failed to create customer");
      return false;
    }
  };

  const subscribeCustomerToPlan = async (customerId, plan) => {
    const priceId =
      plan === "monthly"
        ? "price_1OuDxSSGCJaxmdg5LgkV3BKr"
        : "price_1OuSkkSGCJaxmdg5XMNGXztU";
    try {
      const response = await axios("https://api.stripe.com/v1/subscriptions", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        },
        data: {
          customer: customerId,
          items: [{ price: priceId }],
          payment_settings: {
            payment_method_options: {
              card: {
                request_three_d_secure: "any",
              },
            },
            payment_method_types: ["card"],
            save_default_payment_method: "on_subscription",
          },
          expand: ["latest_invoice.payment_intent"],
        },
      });
      const responseData = response;
      if (!response.status) {
        setError("Failed to subscribe customer to plan");
      } else {
        const confirmPayment = await stripe.confirmCardPayment(
          responseData.data.latest_invoice.payment_intent.client_secret
        );
        if (!confirmPayment.status) {
          // showToast('error', "Failed to subscribe customer to plan");
        } else {
          setError(null);
          setPlan("");
          setProcessing(false);
          return confirmPayment;
        }
      }
      await sendPurchaseConfirmationEmail(plan);

      // Show success toast
      showToast("success", "Email sent successfully");
    } catch (error) {
      showToast("error", "Error subscribing customer to plan");
      setProcessing(false);
      showToast("error", error.message);
      return null;
    }
  };

  const sendPurchaseConfirmationEmail = async (plan) => {
    const price = plan === "monthly" ? "$2.99" : "$29.99";
    const plans_name = plan === "monthly" ? "monthly" : "yearly";
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL_PROD}api/auth/simple_demo `,
        {
          username: formData.username,
          email: formData.email,
          plans_name,
          price,
          id: formData.id,
        }
      );
    } catch (error) {
      console.error("Error sending email:", error);
      showToast("error", "Failed to send purchase confirmation email");
    }
  };
  return (
    <Box py={12}>
      <Box className="subscription-block">
        {plan !== "" ? (
          <>
            <VStack spacing={2} textAlign="center">
              <Heading as="h1" fontSize="4xl">
                Choose a plan to play with you choice
              </Heading>
            </VStack>
            <Stack
              direction={{ base: "column", md: "row" }}
              textAlign="center"
              justify="center"
              spacing={{ base: 4, lg: 10 }}
              my={8}
            >
              <Box
                alignSelf={{ base: "center", lg: "flex-start" }}
                borderRadius={"xl"}
                bg={"black"}
                w={"54%"}
                px={4}
                py={8}
              >
                <div>
                  <InputField
                    name="fullname"
                    placeholder="Enter full name on card"
                    value={formData.fullname}
                    onChange={handleChange}
                  />
                  {errorss && errorss.fullname && (
                    <div className="text-red-500 message sr-field-error">
                      {errorss.fullname}
                    </div>
                  )}
                </div>

                <div className="my-4 border-[1px] rounded-lg px-2 py-2 pb-4">
                  <CardElement
                    className="sr-input sr-card-element"
                    options={CARD_ELEMENT_OPTIONS}
                    onChange={handleCardElementChange}
                  />
                </div>

                {/* {error && (
                  <div className="text-red-500 message sr-field-error">
                    {error}
                  </div>
                )} */}
                {errorss && errorss.cardNumber && (
                  <div className="text-red-500 message sr-field-error">
                    {errorss.cardNumber}
                  </div>
                )}

                <div className="flex items-center justify-between w-full">
                  <button
                    disabled={processing}
                    onClick={(e) => handleSubmit(e)}
                    className="linear mt-2 w-[48%] rounded-xl bg-green-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-green-500 active:bg-green-500"
                  >
                    {processing ? "Processing…" : "Pay"}
                  </button>
                  <button
                    onClick={() => setPlan("")}
                    className="linear mt-2 w-[48%] rounded-xl bg-red-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-700 active:bg-green-500"
                  >
                    {"cancel"}
                  </button>
                </div>
              </Box>
            </Stack>
          </>
        ) : (
          <Pricing plan={plan} setPlan={setPlan} />
        )}
      </Box>
    </Box>
  );
}
