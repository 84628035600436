import axios from "./api";

const ApiService = {
  // GET
  get: async (endpoint, token) => {
    try {
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in GET request:", error);
      throw error;
    }
  },
  // POST
  post: async (endpoint, data, token) => {
    try {
      const response = await axios.post(endpoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
      return error?.response?.data;
    }
  },
  // PUT
  put: async (endpoint, data, token) => {
    try {
      const response = await axios.put(endpoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
      throw error;
    }
  },
  // DELETE
  delete: async (endpoint, token) => {
    try {
      const response = await axios.delete(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in POST request:", error);
      throw error;
    }
  },
};

export default ApiService;
