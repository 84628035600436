import logo from "components/image/logos.jpg";
import Menu from "components/Header/Menu";
import React from "react";
import Sidebar from "./Sidebar";
import { useState } from "react";

export default function Header({ handleClick, userClick }) {

  return (
    <div>
      <div className={`menu-btn ${userClick ? "menuactive" : "menuinactive"}`}
        onClick={handleClick}
      >
        <a href="javascript:;">
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <div className="block sm:hidden">
        <Sidebar user={userClick} />
      </div>

      <div className="hidden sm:block">
        <Menu />
      </div>

      {/* <nav className="mt-8 px-6">
        <ul>
          <li>
            <a
              className="flex items-center gap-x-3 text-sm font-bold text-link hover:text-white mt-4 transition-colors group"
              href="/liked"
            >
              <span className="bg-gradient-to-r from-gra1 to-gra2 rounded-full p-[6px] opacity-70 group-hover:opacity-100">
                <Icon name="menuheart" />
              </span>
              Liked Playlist
            </a>
          </li>
        </ul>
        <div className="h-[1px] bg-active mt-5"></div>
      </nav> */}
    </div>
  );
}
