import { combineReducers } from "redux";
import playerReducer from "./player";
import authReducer from "./authSlice";
import categoryReducer from "./categoriesSlice";
import podcastReducer from "./podcastSlice";
import likedPodcastsReducer from "./likedSlice";

export const rootReducer = combineReducers({
  auth: authReducer,
  category: categoryReducer,
  player: playerReducer,
  podcast: podcastReducer,
  likedPodcasts: likedPodcastsReducer,
});
