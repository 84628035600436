import React, { useEffect } from "react";
import PlayBox from "components/PlayBox";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "apis/apiService";
import { setLikedSlice } from "stores/likedSlice";
import LikedSong from "components/LikedSong";

export default function LikedPodcast() {
  const likedPodcasts = useSelector(
    (state) => state?.likedPodcasts?.likedPodcasts
  );
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state?.auth?.token);

  const getAllLikedPodcasts = async () => {
    let userId = user?.id;
    try {
      let response = await ApiService.get(
        `api/podcasts/liked/${userId}`,
        token
      );
      if (response?.success) {
        if (response?.data?.length > 0) {
          dispatch(setLikedSlice(response?.data));
        }
      } else if (response?.error && response?.statusCode === 200) {
        dispatch(setLikedSlice([]));
      }
    } catch (error) {
      console.log("Network error:", error?.message);
    }
  };

  useEffect(() => {
    getAllLikedPodcasts();
  }, []);

  return (
    <div className="mb-6">
  <div className="flex flex-col sm:flex-row justify-between items-end mb-4 liked-podcasts-page">
    <PlayBox title={"Liked Podcasts"} />
  </div>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 not-like-text">
    {likedPodcasts.length > 0 ? (
      likedPodcasts.map((item) => (
        <LikedSong
          item={item}
          key={item.id}
          getAllLikedPodcasts={getAllLikedPodcasts}
        />
      ))
    ) : (
      <div className="w-full">
        <p className="text-bold text-lg">Like your podcasts and create your own wishlist.</p>
      </div>
    )}
  </div>
</div>

  );
}
