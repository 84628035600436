import Content from "components/Content";
import Bottombar from "components/Bottombar";
import Header from "components/Header";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";

const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const App = () => {
  const [userClick, setuserClick] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);

  const handleClick = () => {
    setuserClick(!userClick);
  };
  const handlemodalopen =(status) => {
    setmodalOpen(status);
  }

  return (
    <Elements stripe={stripeTestPromise}>
      <div className="wrapper">
        <Header handleClick={handleClick} userClick={userClick} />
        <Content userClick={userClick} AdsmodalOpen={handlemodalopen} />
      </div>
      <Bottombar playermodal={modalOpen}/>
    </Elements>
  );
};

export default App;
