import Navigation from "./Navbar/Navigation";
import User from "./Navbar/User";
import { useMatch } from "react-router-dom";
import TopSearch from "./Navbar/TopSearch";

export default function Navbar() {
  const searchRoute = useMatch("/search");

  return (
    <nav className="flex flex-col sm:flex-row items-center justify-between px-4 sm:px-8 h-[8.75rem]">
    <div className="flex items-center justify-between w-full sm:w-auto mb-4 sm:mb-0">
      <Navigation />
    </div>
    <div className="mb-4 sm:mb-0">{searchRoute && <TopSearch />}</div>
    <User />
  </nav>
  
  );
}
