import React, { useState } from "react";
import { Icon } from "components/Icons";
import { useDispatch } from "react-redux";
import { setCurrent } from "stores/player";
import { useSelector } from "react-redux";
import { Badge } from "@chakra-ui/react";
import { useToast } from "./toast/Toaster";
import ApiService from "apis/apiService";

export default function LikedSong({ item, getAllLikedPodcasts }) {
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const { current } = useSelector((state) => state.player);
  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state?.auth?.token);
  const [likedLoading, setLikedLoading] = useState(false);

  const updateCurrent = () => {
    dispatch(setCurrent(item));
  };
  const handleDislikePodcast = async () => {
    setLikedLoading(true);
    if (user) {
      const userId = user?.id;
      const podcast_id = current?.podcast_id;
      let response = await ApiService.put(
        `api/podcasts/dislike/${userId}/${podcast_id}`,
        {},
        token
      );
      if (response?.success) {
        showToast("success", response?.message);
        getAllLikedPodcasts();
      } else {
        showToast("error", response?.message);
      }
      getAllLikedPodcasts();
      setLikedLoading(false);
    } else {
      setLikedLoading(false);
      showToast("error", "Please login first!");
    }
  };

  return (
    <div
      key={item?.podcast_id}
      className={"bg-footer rounded p-4 transition-all hover:bg-menubg group aaaaa"}
    >
      <div className="relative">
        <img
          className="w-auto h-auto inset-0 object-cover mb-4"
          src={
            item?.podcast_image
              ? item?.podcast_image
              : "https://t.scdn.co/images/0a74d96e091a495bb09c0d83210910c3"
          }
        />
        <button
          onClick={updateCurrent}
          className="w-12 h-12 bg-primary absolute right-2 bottom-2 transition-opacity rounded-full flex items-center shadow-2xl justify-center opacity-0 group-hover:opacity-100 group-active:opacity-100"
        >
          <Icon name={!current?.id == item?.id ? "pause" : "play"} />
        </button>
      </div>
      <div>
        <div className="font-semibold text-white text-[16px] whitespace-nowrap truncate">
          {item?.podcast_name}
        </div>
        <div className="mt-1 text-[14px] text-link flex items-center gap-2">
          <Badge colorScheme="green">{item?.added_by}</Badge>
          <Badge colorScheme="purple">
            {item?.podcast_category?.category_name}
          </Badge>
        </div>
      </div>
      <div
        className={
          "h-10 flex items-end justify-end text-sm font-bold text-link transition-colors cursor-pointer"
        }
        onClick={() => handleDislikePodcast()}
      >
        <span className="bg-green-500 hover:bg-greenb-800 text-white hover:text-green-500 rounded-full p-[6px] opacity-90 group-hover:opacity-100">
          <Icon name="menuheart" />
        </span>
      </div>
    </div>
  );
}
