"use client";

import {
  Box,
  Container,
  Stack,
  Text,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  List,
  ListItem,
} from "@chakra-ui/react";
import { MdLocalShipping } from "react-icons/md";
// import logo from "components/image/privacylogo.png";
import { Link } from "react-router-dom";

export default function PrivacyPolicyPage() {
  return (
    <Container maxW={"7xl"}>
      <Stack spacing={{ base: 6, md: 10 }}>
        <Box as={"header"}>
          <center>
            <img
              className="image-privcylogo"
              src='privacylogo.png'
              style={{
                width: "130px",
                marginBottom: "3rem",
                position: "relative",
                left: "-8%",
                height: "130px"
              }}
            ></img>
          </center>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
          >
            PodcastGlance Terms and Conditions
          </Heading>
        </Box>

        <Stack
          spacing={{ base: 4, sm: 6 }}
          direction={"column"}
          // divider={
          //   <StackDivider
          //     borderColor={useColorModeValue("gray.200", "gray.600")}
          //   />
          // }
        >
          <VStack spacing={{ base: 4, sm: 6 }}>
            <Text fontSize={"lg"}>
              Welcome to PodcastGlance! These Terms and Conditions ("Terms")
              govern your use of the website and services provided by
              PodcastGlance. By accessing or using our website and services, you
              agree to be bound by these Terms. Please read them carefully
              before using PodcastGlance.
            </Text>
          </VStack>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              1. Overview
            </Text>
            <Text fontSize={"lg"}>
              PodcastGlance is a website that provides daily podcast summaries
              in audio format. Our goal is to provide users with concise
              summaries of various podcasts, allowing them to quickly grasp the
              main points and content of each episode.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              2. Content Disclaimer
            </Text>
            <Text fontSize={"lg"}>
              The podcast summaries provided by PodcastGlance are for
              informational purposes only. While we strive to ensure accuracy,
              we cannot guarantee the completeness or reliability of the
              information presented. The summaries are based on our
              interpretation of the original podcast episodes and may not fully
              capture all nuances and details. Users are advised to refer to the
              original podcast episodes for the complete context and content.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              3. Use of Content
            </Text>
            <Text fontSize={"lg"}>
              All content provided on PodcastGlance, including but not limited
              to podcast summaries, graphics, logos, and images, are the
              intellectual property of PodcastGlance or its content providers.
              You may not reproduce, modify, distribute, display, or create
              derivative works of any content on our website without prior
              written permission from PodcastGlance.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              4. User Conduct
            </Text>
            <Text fontSize={"lg"}>
              {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}> */}
              a. You will not use the website for any unlawful or unauthorized
              purposes.
              <Text fontSize={"lg"}>
                {" "}
                b. You will not attempt to gain unauthorized access to our
                systems or data or engage in any hacking or unauthorized data
                mining.
              </Text>
              <Text fontSize={"lg"}>
                {" "}
                c. You will not engage in any activity that disrupts or
                interferes with the operation of the website or the experience
                of other users.
              </Text>
              <Text fontSize={"lg"}>
                {" "}
                d. You will not use any automated tools, bots, or scripts to
                access or interact with the website.
              </Text>
              <Text fontSize={"lg"}>
                e. You will not upload, post, or transmit any content that is
                harmful, offensive, abusive, or violates any applicable laws or
                regulations.
              </Text>
              {/* </SimpleGrid> */}
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              5. Privacy Policy
            </Text>
            <Text fontSize={"lg"}>
              Your privacy is important to us. Please refer to our Privacy
              Policy to understand how we collect, use, and protect your
              personal information when you use PodcastGlance.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              6. Disclaimer of Warranties
            </Text>
            <Text fontSize={"lg"}>
              PodcastGlance provides the website and services on an "as is"
              basis. We do not make any warranties or representations, express
              or implied, regarding the accuracy, reliability, or completeness
              of the content provided. We disclaim all warranties, including but
              not limited to warranties of merchantability, fitness for a
              particular purpose, and non-infringement.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              7. Limitation of Liability
            </Text>
            <Text fontSize={"lg"}>
              In no event shall PodcastGlance or its affiliates be liable for
              any indirect, incidental, special, consequential, or punitive
              damages arising out of or in connection with the use or inability
              to use the website or services, even if we have been advised of
              the possibility of such damages.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              8. Changes to Terms and Conditions
            </Text>
            <Text fontSize={"lg"}>
              PodcastGlance reserves the right to modify or update these Terms
              and Conditions at any time without prior notice. Any changes will
              be effective immediately upon posting the revised version on the
              website. Your continued use of PodcastGlance after such changes
              constitutes your acceptance of the updated Terms.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              9. Termination
            </Text>
            <Text fontSize={"lg"}>
              PodcastGlance may suspend or terminate your access to the website
              and services at any time for any reason without notice. You agree
              that PodcastGlance shall not be liable to you or any third party
              for any such termination.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              10. Governing Law
            </Text>
            <Text fontSize={"lg"}>
              These Terms and Conditions shall be governed by and construed in
              accordance with the laws of [Your Jurisdiction], without regard to
              its conflicts of law principles.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              11. Contact Us
            </Text>
            <Text fontSize={"lg"}>
              If you have any questions or concerns regarding these Terms and
              Conditions, please contact us at{""}
              <a href="mailto:info@podcastglance.com">
                {" "}
                info@podcastglance.com
              </a>
              . By using PodcastGlance, you acknowledge that you have read,
              understood, and agreed to these Terms and Conditions. At
              PodcastGlance, we are committed to safeguarding your privacy and
              protecting your personal information. This Privacy Policy outlines
              the types of information we collect when you use our website and
              how we use, share, and protect that information. By accessing or
              using PodcastGlance, you consent to the practices described in
              this policy.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              Information We Collect:
            </Text>
            <Text fontSize={"lg"}>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <List spacing={2}>
                  <ListItem>
                    {" "}
                    a. Personal Information: When you use PodcastGlance, we may
                    collect personally identifiable information, such as your
                    name and email address, when you voluntarily provide it to
                    us, for example, when you subscribe to our website.
                  </ListItem>
                  <ListItem>
                    {" "}
                    b. Non-Personal Information: We may collect non-personal
                    information, such as your IP address, browser type, device
                    information, and usage patterns, when you interact with our
                    website. This information is collected through cookies, web
                    beacons, and similar technologies and is used to improve our
                    website's functionality and user experience.
                  </ListItem>{" "}
                  <ListItem>
                    c. Podcast Listening Data: We may collect information about
                    your podcast listening habits and preferences to improve our
                    podcast recommendations and better understand our audience.
                    This data will be anonymized and aggregated to protect your
                    privacy.
                  </ListItem>
                </List>
              </SimpleGrid>
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              How We Use Your Information:
            </Text>
            <Text fontSize={"lg"}>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <List spacing={2}>
                  <ListItem>
                    {" "}
                    a. Personal Information: We may use your personal
                    information to send you newsletters, updates, and other
                    promotional materials related to PodcastGlance. You can
                    opt-out of receiving these communications at any time by
                    contacting us directly.
                  </ListItem>
                  <ListItem>
                    {" "}
                    b. Non-Personal Information: We use non-personal information
                    to analyze website usage trends, diagnose technical issues,
                    and improve our website's performance and content.
                  </ListItem>{" "}
                  <ListItem>
                    c. Podcast Listening Data: The podcast listening data we
                    collect is used solely for internal purposes to enhance our
                    podcast recommendations and to provide more relevant content
                    to our users.
                  </ListItem>
                </List>
              </SimpleGrid>
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              Sharing Your Information:
            </Text>
            <Text fontSize={"lg"}>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <List spacing={2}>
                  <ListItem>
                    {" "}
                    a. We do not sell, rent, or lease your personal information
                    to third parties.
                  </ListItem>
                  <ListItem>
                    {" "}
                    b. We may share your personal information with trusted
                    third-party service providers who assist us in operating our
                    website or conducting our business. These service providers
                    are bound by confidentiality agreements and are not allowed
                    to use your information for any other purpose.
                  </ListItem>{" "}
                  <ListItem>
                    c. In some cases, we may be required to disclose your
                    personal information to comply with legal obligations,
                    enforce our Terms of Service, or protect our rights,
                    property, or safety.
                  </ListItem>
                </List>
              </SimpleGrid>
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              Data Security:
            </Text>
            <Text fontSize={"lg"}>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <List spacing={2}>
                  <ListItem>
                    {" "}
                    a. We implement reasonable security measures to protect your
                    personal information from unauthorized access, disclosure,
                    alteration, or destruction.
                  </ListItem>
                  <ListItem>
                    {" "}
                    b. Despite our efforts, no data transmission over the
                    internet or electronic storage method is entirely secure. We
                    cannot guarantee the absolute security of your information.
                  </ListItem>{" "}
                </List>
              </SimpleGrid>
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              Changes to the Privacy Policy:
            </Text>
            <Text fontSize={"lg"}>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. Any changes will be effective immediately upon
              posting the updated policy on our website. Please review this page
              periodically for the latest information on our privacy practices.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              Contact Us:
            </Text>
            <Text fontSize={"lg"}>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at
              <Link to="/contact-us"> info@podcastglance.com</Link>.
            </Text>
          </Box>
          <Box>
            <Text
              fontSize={{ base: "20px", lg: "25px" }}
              color={"white"}
              fontWeight={"700"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              About US
            </Text>
            <Text fontSize={"lg"}>
              Welcome to PodcastGlance, your go-to destination for daily podcast
              summaries in audio format! We understand that in today's
              fast-paced world, finding time to listen to all the podcasts you
              love can be a challenge. That's why we've created PodcastGlance, a
              platform dedicated to providing concise and informative summaries
              of your favorite podcasts, making it easier for you to stay
              up-to-date and informed. At PodcastGlance, we believe that
              podcasts are a powerful medium for sharing knowledge, ideas, and
              stories. They offer a diverse range of content, from educational
              and informative discussions to entertaining and thought-provoking
              storytelling. However, we also recognize that not everyone has the
              luxury of listening to full-length podcasts every day due to their
              busy schedules. Our mission is to bridge that gap by curating
              daily podcast summaries that capture the essence of each episode.
              PodcastGlance crafts succinct summaries that highlight the key
              takeaways, insights, and discussions. Whether you're interested in
              the latest trends, personal development, business, true crime,
              health, or any other topic, we've got you covered! PodcastGlance
              is designed to be user-friendly and accessible to everyone. You
              can browse through our ever-expanding library of podcast
              summaries, organized by category, date, or popularity. Each
              summary is presented in an engaging audio format, so you can
              listen to it while commuting, working out, or taking a short break
              during your busy day. PodcastGlance is committed to maintaining a
              high standard of quality and accuracy in every summary we produce.
              We value your trust and strive to be a reliable source of podcast
              insights, so you can always count on us for the latest updates and
              fresh perspectives. Thank you for joining us on this exciting
              journey of exploration and discovery through the world of
              podcasts. Whether you're a seasoned podcast enthusiast or just
              getting started, PodcastGlance is here to enhance your podcasting
              experience. Tune in daily and let us be your guide to the
              fascinating world of podcasts in a glance! Remember, life is
              short, and there's a world of knowledge waiting for you - let
              PodcastGlance be your guide! Welcome aboard, and let's explore the
              most engaging podcasts together, one summary at a time!
            </Text>
          </Box>
          <Box mt={10} textAlign="center">
            <Text fontSize="3xl" fontWeight="bold">
              By using PodcastGlance, you agree to the terms outlined in this
              Privacy Policy. Thank you for trusting us with your personal
              information.
            </Text>
          </Box>

          {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
              <List spacing={2}>
                <ListItem>Chronograph</ListItem>
                <ListItem>Master Chronometer Certified</ListItem>{" "}
                <ListItem>Tachymeter</ListItem>
              </List>
              <List spacing={2}>
                <ListItem>Anti‑magnetic</ListItem>
                <ListItem>Chronometer</ListItem>
                <ListItem>Small seconds</ListItem>
              </List>
            </SimpleGrid> */}
          {/* <Box>
            <Text
              fontSize={{ base: "16px", lg: "18px" }}
              color={useColorModeValue("yellow.500", "yellow.300")}
              fontWeight={"500"}
              textTransform={"uppercase"}
              mb={"4"}
            >
              Product Details
            </Text>

            <List spacing={2}>
              <ListItem>
                <Text as={"span"} fontWeight={"bold"}>
                  Between lugs:
                </Text>{" "}
                20 mm
              </ListItem>
              <ListItem>
                <Text as={"span"} fontWeight={"bold"}>
                  Bracelet:
                </Text>{" "}
                leather strap
              </ListItem>
              <ListItem>
                <Text as={"span"} fontWeight={"bold"}>
                  Case:
                </Text>{" "}
                Steel
              </ListItem>
              <ListItem>
                <Text as={"span"} fontWeight={"bold"}>
                  Case diameter:
                </Text>{" "}
                42 mm
              </ListItem>
              <ListItem>
                <Text as={"span"} fontWeight={"bold"}>
                  Dial color:
                </Text>{" "}
                Black
              </ListItem>
              <ListItem>
                <Text as={"span"} fontWeight={"bold"}>
                  Crystal:
                </Text>{" "}
                Domed, scratch‑resistant sapphire crystal with anti‑reflective
                treatment inside
              </ListItem>
              <ListItem>
                <Text as={"span"} fontWeight={"bold"}>
                  Water resistance:
                </Text>{" "}
                5 bar (50 metres / 167 feet){" "}
              </ListItem>
            </List>
          </Box> */}
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent={"center"}>
          <MdLocalShipping />
          <Text>2-3 business days delivery</Text>
        </Stack> */}
      </Stack>
    </Container>
  );
}
