import React, { useEffect, useState } from "react";
import { Audio } from "react-loader-spinner";
// components
import Song from "components/Song";
import { useSelector } from "react-redux";
import { Badge, Stat, StatLabel, StatNumber } from "@chakra-ui/react";

const bgColors = [
  "purple.400",
  "blue.400",
  "green.400",
  "red.400",
  "yellow.400",
];

export default function Search() {
  const categories = useSelector((state) => state.category?.categories);
  const podcasts = useSelector((state) => state.podcast?.podcasts);
  // states
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <div>
  {loading ? (
    <div className="flex w-full h-[75vh] items-center justify-center">
      <Audio
        height="40"
        width="40"
        color="#1BD760"
        ariaLabel="audio-loading"
        wrapperStyle={{}}
        wrapperClass="wrapper-class"
        visible={true}
      />
    </div>
  ) : (
    <div>
      {/* Artists Section */}
      <div className="mb-4">
        <div className="flex flex-col items-center justify-center sm:flex-row sm:items-center sm:justify-between">
          <h3 className="text-lg md:text-2xl text-white font-bold tracking-tight pb-2 mb-2 sm:mb-0">
          Podcast Hosts
          </h3>
          <p
            className="text-gray-300 cursor-pointer text-sm"
            // onClick={() => handlePageNumber(pageNumber > 3 ? "less" : "more")}
          >
            {categories?.length > 3 ? "See less" : "See all"}
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 sm:gap-x-5 gap-y-4 mb-6 home-playlist">
          {categories?.length > 0 ? (
            categories?.map((item, index) => (
              <a href={`categoryDetails/${item?.category_id}`} key={item?.category_id}>
                <Stat
                  px={{ base: 4, md: 8 }}
                  py={{ base: 4, md: 5 }}
                  shadow={"xl"}
                  border={"1px solid"}
                  rounded={"lg"}
                  backgroundColor={bgColors[index % bgColors.length]}
                  borderColor={bgColors[index % bgColors.length]}
                >
                  <StatLabel fontWeight={"bold"} fontSize={{ base: "sm", md: "sm" }} isTruncated>
                    {item?.category_name}
                  </StatLabel>
                  <StatNumber fontSize={{ base: "sm", md: "sm" }} fontWeight={"medium"}>
                    <Badge colorScheme="gray">{item?.added_by}</Badge>
                  </StatNumber>
                </Stat>
              </a>
            ))
          ) : (
            <div className="w-full">
              <p className="text-bold text-lg">Artists not found</p>
            </div>
          )}
        </div>
      </div>

      {/* Podcasts Section */}
      <div className="mb-6">
        <h3 className="text-lg md:text-2xl text-white font-bold tracking-tight mb-4">Podcast Playlists</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 sm:gap-5">
          {podcasts.length > 0 ? (
            podcasts.map((item) => <Song item={item} key={item.id} />)
          ) : (
            <div className="w-full">
              <p className="text-bold text-lg">Podcasts not found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )}
</div>


  );
}
