"use client";

import {
  Container,
  Flex,
  Box,
  Heading,
  Button,
  VStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";
import { MdEmail, MdOutlineEmail } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { Spinner } from "@chakra-ui/react";
import ApiService from "apis/apiService";
import { useState } from "react";
import { useToast } from "components/toast/Toaster";

export default function ContactUsPage() {
  const { showToast } = useToast();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSendQuery = async () => {
    let payload = {};

    if (!name) {
      showToast("error", "Name is required");
    } else if (!email) {
      showToast("error", "Email is required");
    } else if (!message) {
      showToast("error", "Message is required");
    } else {
      payload = {
        name: name,
        email: email,
        message: message,
      };
      setLoading(true);

      try {
        const response = await ApiService.post("api/query/send-query", payload);
        if (response?.success) {
          showToast("success", response?.message);
          setName("");
          setEmail("");
          setMessage("");
        } else {
          showToast("error", response?.message);
        }
        setLoading(false);
      } catch (error) {
        console.log("[Networ Error]", error);
        showToast("error", error.message);
        setLoading(false);
      }
    }
  };

  return (
    <Container maxW="full" mt={0} centerContent overflow="hidden" className="contactus-page">
      <Flex>
        <Box
          bg="#2AB455"
          color="black"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}
        >
          <Box p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              <WrapItem>
                <Box display={"flex"} flexDirection={"column"}>
                  <Heading color={"white"}>Contact</Heading>
                  <Box py={{ base: 4, sm: 4, md: 6, lg: 8 }}>
                    <VStack>
                      <Button
                        size="md"
                        height="48px"
                        width="250px"
                        variant="ghost"
                        color="white"
                        outline={"none"}
                        textDecoration={"none"}
                        _hover={{ bg: "#2AB455" }}
                        leftIcon={<MdEmail color="white" size="25px" />}
                      >
                        info@podcastglance.com
                      </Button>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box bg="white" borderRadius="lg">
                  <Box m={12} lg={12} color="#0B0E3F">
                    <VStack spacing={5}>
                      <FormControl id="name">
                        <FormLabel>Full Name</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement pointerEvents="none">
                            <BsPerson color="gray.800" />
                          </InputLeftElement>
                          <Input
                            type="text"
                            size="md"
                            placeholder="John Doe"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name">
                        <FormLabel>Email</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineEmail color="gray.800" />
                          </InputLeftElement>
                          <Input
                            type="text"
                            size="md"
                            placeholder="abc@abc.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name">
                        <FormLabel>Message</FormLabel>
                        <Textarea
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          borderColor="gray.300"
                          _hover={{
                            borderRadius: "gray.300",
                          }}
                          placeholder="Message..."
                        />
                      </FormControl>
                      <FormControl id="name" float="right">
                        <Button
                          disabled={loading}
                          variant="solid"
                          bg="#2AB455"
                          color="white"
                          _hover={{
                            bg: "gray",
                          }}
                          onClick={() => handleSendQuery()}
                        >
                          {loading ? <Spinner /> : "Send Message"}
                        </Button>
                      </FormControl>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
}
