import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import logo from "components/image/logos.jpg";
import { Icon } from "components/Icons";
import { FaIdCard } from "react-icons/fa";

const Sidebar = ({ user }) => {
  const [activeLink, setActiveLink] = useState("http://localhost:3000/");
  return (
    <div className={`w-20 pt-6 bg-black flex flex-col flex-shrink-0 podcast-left-sidebar ${user == true ? "sidebarActive" : "sidbarInactive"
      }`}>
      <div
        className={`container flex flex-col items-center justify-center ${user == true ? "sidebarActive" : "sidbarInactive"
      }`}
      >
        <div className="sidebar p-2">
          <div className="">
            <a href="#" className="mb-7 pb-5 flex">
              <img className="h-10 w-auto" src={logo} />
            </a>
          </div>
          <NavLink
            activeClassName="!text-white"
            to={"/"}
            onClick={() => setActiveLink("http://localhost:3000/")}
            exact
            className={`pl-3 pb-8 ${activeLink === "http://localhost:3000/"
              ? "text-green-500"
              : "text-white"
              }`}
          >
            {window.location.href === "http://localhost:3000/" ? (
              <Icon name="acthome" />
            ) : (
              <Icon name="home" />
            )}
          </NavLink>
          <NavLink
            activeClassName="!text-white"
            to={"/search"}
            onClick={() => setActiveLink("http://localhost:3000/search")}
            className={`pl-3 pb-8 ${activeLink === "http://localhost:3000/search"
              ? "text-green-500"
              : "text-white"
              }`}
          >
            {window.location.href === "http://localhost:3000/search" ? (
              <Icon name="actsearch" />
            ) : (
              <Icon name="search" />
            )}
          </NavLink>
          <NavLink
            activeClassName="!text-white"
            to={"/liked"}
            onClick={() => setActiveLink("http://localhost:3000/liked")}
            className={`pt-8 pb-8 ${activeLink === "http://localhost:3000/liked"
              ? "text-green-500"
              : "text-white"
              } h-10 flex items-center text-sm font-bold text-link hover:text-white gap-2 sm:gap-2 transition-colors`}
          >
            <span className="bg-green-500 rounded-full p-[6px] opacity-90 group-hover:opacity-100">
              <Icon name="menuheart" />
            </span>
          </NavLink>
          <NavLink
            activeClassName="!text-white"
            to={"/subscription"}
            onClick={() => setActiveLink("http://localhost:3000/liked")}
            className={`pt-4 pb-8 ${activeLink === "http://localhost:3000/liked"
              ? "text-green-500"
              : "text-white"
              } h-10 flex items-center text-sm font-bold text-link hover:text-white gap-2 sm:gap-2 transition-colors`}
          >
            <span className="bg-green-500 rounded-full p-[6px] opacity-90 group-hover:opacity-100">
              <FaIdCard color="white" />
            </span>
          </NavLink>
        </div>
      </div>
      <div className="mobile-logoimg">
        <a href="#" className="mb-7 pb-5 flex mobile">
          <img className="h-10 w-auto" src={logo} />
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
