import React, { useEffect, useState } from "react";
import PlayBox from "components/PlayBox";
import Song from "components/Song";
import { Audio } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function CategoryDetails() {
  const params = useParams();
  let categoryId = params?.id;
  const podcasts = useSelector((state) => state.podcast?.podcasts);
  const [loading, setLoading] = useState(false);
  const [filteredPodcasts, setFilteredPodcasts] = useState([]);

  const getPodcastNameById = (category_id) => {
    setLoading(true);
    const podcast = podcasts.filter((item) => {
      return item?.podcast_category?._id === category_id;
    });
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    return podcast;
  };

  useEffect(() => {
    const data = getPodcastNameById(categoryId);
    setFilteredPodcasts(data);
  }, []);

  return (
    <div className="p-4">
      {loading ? (
        <div className="flex items-center justify-center h-1/2">
          <Audio
            height="30"
            width="30"
            color="#1BD760"
            ariaLabel="audio-loading"
            wrapperClass="wrapper-class"
            visible={true}
          />
        </div>
      ) : (
        <div>
          <div className="mb-4">
            <PlayBox title="Podcast Playlists" />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {filteredPodcasts && filteredPodcasts.length > 0 ? (
              filteredPodcasts.map((item) => <Song item={item} key={item.id} />)
            ) : (
              <div className="w-full">
                <p className="text-bold text-lg">
                  Podcasts not found by this artist
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
