import { Icon } from "components/Icons";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import logo from "components/image/logos.jpg";
import { FaIdCard } from "react-icons/fa";
export default function Menu() {
  const [activeLink, setActiveLink] = useState("http://localhost:3000/");

  return (
    <div className="w-60 h-screen pt-6 bg-black flex flex-col flex-shrink-0">
      <a href="#" className="mb-7 px-6 flex items-center justify-center">
        <img className="h-20 w-auto" src={logo} />
      </a>
      <div id="tummenu">
        <nav className="px-2">
          <ul className="flex flex-col sm:flex-col">
            <li>
              <NavLink
                activeClassName="!text-white"
                to={"/"}
                onClick={() => setActiveLink("http://localhost:3000/")}
                exact
                className={`h-10 flex items-center text-sm font-bold text-link hover:text-white px-4 gap-2 sm:gap-4 transition-colors ${
                  activeLink === "http://localhost:3000/"
                    ? "text-green-500"
                    : "text-white"
                }`}
              >
                {window.location.href === "http://localhost:3000/" ? (
                  <Icon name="acthome" />
                ) : (
                  <Icon name="home" />
                )}
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="!text-white"
                to={"/search"}
                onClick={() => setActiveLink("http://localhost:3000/search")}
                className={`h-10 flex items-center text-sm font-bold text-link hover:text-white px-4 gap-2 sm:gap-4 transition-colors ${
                  activeLink === "http://localhost:3000/search"
                    ? "text-green-500"
                    : "text-white"
                }`}
              >
                {window.location.href === "http://localhost:3000/search" ? (
                  <Icon name="actsearch" />
                ) : (
                  <Icon name="search" />
                )}
                Search Podcast
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="!text-white"
                to={"/liked"}
                onClick={() => setActiveLink("http://localhost:3000/liked")}
                className={`h-10 flex items-center text-sm font-bold text-link hover:text-white px-4 gap-2 sm:gap-4 transition-colors ${
                  activeLink === "http://localhost:3000/liked"
                    ? "text-green-500"
                    : "text-white"
                }`}
              >
                <span className="bg-green-500 rounded-full p-[6px] opacity-90 group-hover:opacity-100">
                  <Icon name="menuheart" />
                </span>
                Liked Podcasts
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="!text-white"
                to={"/subscription"}
                onClick={() =>
                  setActiveLink("http://localhost:3000/subscription")
                }
                className={`h-10 flex items-center text-sm font-bold text-link hover:text-white px-4 gap-2 sm:gap-4 transition-colors ${
                  activeLink === "http://localhost:3000/subscription"
                    ? "text-green-500"
                    : "text-white"
                }`}
              >
                <span className="bg-green-500 rounded-full p-[6px] opacity-90 group-hover:opacity-100">
                  <FaIdCard color="white" />
                </span>
                Subscription
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
