"use client";

import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";

function PriceWrapper(props) {
  const { children } = props;

  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export default function Pricing({ plan, setPlan }) {
  const moveToSubscription = (plan_id) => {
    setPlan(plan_id);
    console.log("plan_id", plan_id);
  };

  return (
    <Box py={12}>
      {/* <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Plans that fit your need
        </Heading>
        <Text fontSize="lg" color={"gray.300"}>
          Ad-free Music, Unlimited and, Exclusive Original Content and More
        </Text>
      </VStack> */}
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="2xl">
          Ad-free podcasts, get the cliff notes of the podcast world, 15 minutes
          a day - smarter than ever
        </Heading>
        <Text fontSize="2xl" color={"gray.300"}>
          Subscribe to PodcastGlance for daily audio summaries.
        </Text>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Monthly
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                2.99
              </Text>
              <Text fontSize="2xl" color="gray.300">
                /month
              </Text>
            </HStack>
          </Box>
          <VStack bg={"black"} py={4} borderBottomRadius={"xl"}>
            {/* <List spacing={3} textAlign="start" px={12}>
              <ListItem color={"black"}>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Unlimited build minutes
              </ListItem>
              <ListItem color={"black"}>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Lorem, ipsum dolor.
              </ListItem>
            </List> */}
            <Box w="80%" pt={7}>
              <Button
                onClick={() => moveToSubscription("monthly")}
                w="full"
                backgroundColor={"green.500"}
                variant="solids"
              >
                <Text>Buy now</Text>
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Yearly
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                29.99
              </Text>
              <Text fontSize="2xl" color="gray.300">
                /year
              </Text>
            </HStack>
          </Box>
          <VStack bg={"black"} py={4} borderBottomRadius={"xl"}>
            {/* <List spacing={3} textAlign="start" px={12}>
              <ListItem color={"black"}>
                <ListIcon as={FaCheckCircle} color="green.400" />
                Unlimited build minutes
              </ListItem>
              <ListItem color={"black"}>
                <ListIcon as={FaCheckCircle} color="green.400" />
                Lorem, ipsum dolor.
              </ListItem>
            </List> */}
            <Box w="80%" pt={7}>
              <Button
                onClick={() => moveToSubscription("yearly")}
                w="full"
                backgroundColor={"green.500"}
                variant="solids"
              >
                <Text>Buy now</Text>
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>
    </Box>
  );
}
